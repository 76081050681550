<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Module.Finance')" />
        <div class="top">
            <div class="top-content animate__animated animate__bounceInLeft">
                <div class="content1">{{$t('Finance.TopTitle')}}</div>
                <div class="content2">
                    {{$t('Finance.TopDesc')}}
                </div>
            </div>
        </div>
        <div class="main">
            <div class="product-box">
                <div class="operate-box">
                    <div class="tab-list">
                        <div v-for="(item,index) in tabList" 
                        :key="index" 
                        :class="['tab-item', curIndex == index ? 'active' : '']" 
                        @click="changeCurIndex(index)">
                            {{item}}
                        </div>
                    </div>
                    <div class="record" @click="to('/h5/finance/record')">
                        {{$t('Finance.FinancialRecords')}}
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div class="product-list">
                    <div v-for="(item,index) in list[curIndex]" :key="index" class="product-item">
                        <div class="first">
                            <img class="img" :src="item.image" />
                            <div class="name">{{item.title}}</div>
                        </div>
                        <div class="info-list">
                            <div class="info-item">
                                <div class="value">{{(365 * parseFloat(item.rate)).toFixed(2)}}%</div>
                                <div class="label">{{$t('Finance.ReferenceAnnualized')}}</div>
                            </div>
                            <div class="info-item">
                                <div class="value">{{item.day}}{{$t('Finance.day')}}</div>
                                <div class="label">{{item.min_num}}USDT{{$t('Finance.Unlock')}}</div>
                            </div>
                        </div>
                        <div class="progress-box">
                            <el-progress :percentage="(parseFloat(item.has_num) / parseFloat(item.open_num)) * 100" :show-text="false" :stroke-width="10" define-back-color="#F4F4F4" color="#5466EF"></el-progress>
                            <span class="progress-text">{{((parseFloat(item.has_num) / parseFloat(item.open_num)) * 100).toFixed(2)}}%</span>
                        </div>
                        <div class="position-list">
                            <div class="position-item">
                                <div class="label">{{$t('Finance.RemainingPosition')}}:</div>
                                <div class="value">{{(parseFloat(item.open_num) - parseFloat(item.has_num)).toFixed(8)}}USDT</div>
                            </div>
                            <div class="position-item">
                                <div class="label">{{$t('Finance.LockedPositions')}}:</div>
                                <div class="value">{{item.has_num}}USDT</div>
                            </div>
                        </div>
                        <div v-if="curIndex != 2 && item.time_differ" class="end-time">
                            <div class="label">{{$t('Finance.EndOfDistance')}}:</div>
                            <div class="value">
                                {{item.time_differ[0]}}{{$t('Finance.day')}} {{item.time_differ[1]}}:{{item.time_differ[2]}}:{{item.time_differ[3]}}
                            </div>
                        </div>
                        <div v-if="curIndex == 0" class="join-btn" @click="toDetail(item.id)">{{$t('Finance.JoinNow')}}</div>
                    </div>
                </div>
                <!-- <div class="pagination-box">
                    <el-pagination small @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="11"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="total, prev, pager, next, jumper"
                        :total="total" background>
                    </el-pagination>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue')
        },
        data() {
            return {
                tabList: [this.$t('Finance.InProgress'), this.$t('Finance.AboutToStart'), this.$t('Finance.ItsOver')],
                curIndex: 0,
                list: [
                    [],
                    [],
                    []
                ],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                nowTime: ''
            }
        },
        created() {
            this.getList()
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            toDetail(id) {
            	this.$router.push({ path: '/h5/finance/details', query: {id} })
            },
            
            changeCurIndex(index) {
                this.curIndex = index
            },
            
            getList() {
                this.$api.financeList().then(res => {
                    this.list = res.data
                    this.nowTime = new Date().format("yyyy-MM-dd hh:mm:ss")
                    this.list.map(arr => {
                        arr.map(item => {
                            item['time_differ'] = this.calCountdown(this.nowTime, item.end_at)
                        })
                    })
                })
            },
            
            calCountdown(time1,time2) {
                let Time1 = Date.parse(time1)
                let Time2 = Date.parse(time2)
                let timestrap = (Time2 - Time1) / 1000 //开始得出时间差,然后计算
                //得到天 格式化成前缀加零的样式
                let d = parseInt(timestrap / 60 / 60 / 24)
                d = d < 10 ? '0' + d : d
                //得到小时 格式化成前缀加零的样式
                let h = parseInt(timestrap / 60 / 60 % 24)
                h = h < 10 ? '0' + h : h
                //得到分钟 格式化成前缀加零的样式
                let m = parseInt(timestrap / 60 % 60)
                m = m < 10 ? '0' + m : m
                //得到秒 格式化成前缀加零的样式
                let s = parseInt(timestrap % 60)
                s = s < 10 ? '0' + s : s
            
                return [d, h, m, s]
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .top {
            width: 100%;
            box-sizing: border-box;
            padding: 2rem 1.2rem;
            background: url(~@/assets/images/h5/finance/finance-bg.png) no-repeat;
            background-size: 100% 100%;
            
            .top-content {
                width: 60%;
                animation-delay: 200ms;
                animation-duration: 1000ms;
                
                .content1 {
                    width: 100%;
                    margin-bottom: 1.2rem;
                    font-size: 2.3rem;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                .content2 {
                    width: 100%;
                    font-size: 1.2rem;
                    color: #000000;
                    word-wrap: break-word;
                }
            }
        }
        
        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 2rem;
            background-color: #FFFFFF;
            
            .product-box {
                width: 100%;
                
                .operate-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid #E9E9E9;
                    margin-bottom: 1.6rem;
                    
                    .tab-list {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                        
                        .tab-item {
                            height: 3.2rem;
                            line-height: 3.2rem;
                            box-sizing: border-box;
                            padding: 0 1rem;
                            border-radius: 2px;
                            font-size: 1.2rem;
                            color: #000000;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #F5F6F9;
                            cursor: pointer;
                            
                            &:not(:last-child) {
                                margin-right: 3px;
                            }
                        }
                        
                        .active {
                            color: #FFFFFF;
                            background-color: #5466EF;
                        }
                    }
                    
                    .record {
                        display: flex;
                        align-items: center;
                        font-size: 1.2rem;
                        color: #FF961D;
                        white-space: nowrap;
                        cursor: pointer;
                        
                        .el-icon-arrow-right {
                            margin-left: 2px;
                            font-size: 1.2rem;
                            color: #FF961D;
                        }
                    }
                }
                
                .product-list {
                    width: 100%;
                    
                    .product-item {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 1.6rem 1.6rem 2rem;
                        border-radius: 4px;
                        background-image: linear-gradient(#F7F8FC,#FFFFFF,#FFFFFF);
                        box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.1);
                        
                        &:not(:last-child) {
                            margin-bottom: 1.6rem;
                        }
                        
                        .first {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-bottom: 1.4rem;
                            
                            .img {
                                min-width: 10.5rem;
                                max-width: 10.5rem;
                                margin-right: 1.6rem;
                            }
                            
                            .name {
                                width: 100%;
                                line-height: 160%;
                                font-size: 1.6rem;
                                font-weight: bold;
                                color: #000000;
                                word-wrap: break-word;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                        
                        .info-list {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-bottom: 1.4rem;
                            
                            .info-item {
                                max-width: 48%;
                                
                                .value {
                                    width: 100%;
                                    margin-bottom: 4px;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #E05E51;
                                    word-break: break-all;
                                }
                                
                                .label {
                                    width: 100%;
                                    font-size: 1.3rem;
                                    color: #787A8A;
                                    word-wrap: break-word;
                                }
                            }
                        }
                        
                        .progress-box {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            
                            /deep/ .el-progress {
                                width: 100%;
                            }
                            
                            /deep/ .el-progress-bar {
                                padding: 0;
                                margin: 0;
                            }
                            
                            .progress-text {
                                margin-left: 1rem;
                            	font-size: 1.3rem;
                            	color: #5466EF;
                                white-space: nowrap;
                            }
                        }
                        
                        .position-list {
                            width: 100%;
                            box-sizing: border-box;
                            padding: 1rem 0.8rem;
                            margin: 1.4rem 0 1.2rem;
                            border-radius: 2px;
                            background-color: #F5F6F9;
                            
                            .position-item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                
                                &:not(:last-child) {
                                    margin-bottom: 8px;
                                }
                                
                                .label {
                                    font-size: 1.3rem;
                                    color: #787A8A;
                                    white-space: nowrap;
                                }
                                
                                .value {
                                    max-width: 100%;
                                    font-size: 1.4rem;
                                    font-weight: bold;
                                    color: #000000;
                                    word-break: break-all;
                                }
                            }
                        }
                        
                        .end-time {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            box-sizing: border-box;
                            padding: 0 0.8rem;
                            margin-bottom: 2.4rem;
                            
                            .label {
                                font-size: 1.3rem;
                                color: #686876;
                                white-space: nowrap;
                            }
                            
                            .value {
                                max-width: 100%;
                                font-size: 1.5rem;
                                font-weight: bold;
                                color: #FF961D;
                                word-break: break-all;
                            }
                        }
                        
                        .join-btn {
                            width: 100%;
                            height: 4.5rem;
                            line-height: 4.5rem;
                            box-sizing: border-box;
                            border-radius: 4px;
                            font-size: 1.4rem;
                            font-weight: bold;
                            color: #FFFFFF;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #5466EF;
                            cursor: pointer;
                        }
                    }
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 2rem 0 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #5466EF;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #5466EF;
                    }
                }
            }
        }
    }
</style>